import validate from 'validate.js';
import Toastify from 'toastify-js';
import { AsYouType } from 'libphonenumber-js';
import { parsePhoneNumberFromString as parseMin } from 'libphonenumber-js/max'
import "toastify-js/src/toastify.css"

document.addEventListener('DOMContentLoaded',function() {

    const init = function(el, constraints) {
        const form = el;

        form.addEventListener("submit", (ev)=> {
            ev.preventDefault();
            handleFormSubmit(form);
        });

        const inputs = form.querySelectorAll("input, textarea, select");

        for (let i = 0; i < inputs.length; ++i) {
            inputs.item(i).addEventListener("change", (ev)=> {
                const errors = validate(form, constraints, { fullMessages: false }) || {};
                console.clear();
                console.log(errors);
                showErrorsForInput(ev.currentTarget, errors[ev.currentTarget.name])
            });
        }

        function handleFormSubmit(form, input) {
            const errors = validate(form, constraints, { fullMessages: false });
            showErrors(form, errors || {});
            if (!errors) {
                showSuccess();
            } else {
              if (document.querySelector('#ask')) {
                window.runScroll('#ask');
              }
            }
        };

        // Updates the inputs with the validation errors
        function showErrors(form, errors) {
            _.each(form.querySelectorAll("[required]"), (input)=> {
                showErrorsForInput(input, errors && errors[input.name]);
            });
        };

        // Shows the errors for a specific input
        function showErrorsForInput(input, errors) {
            const formGroup = closestParent(input.parentNode, "form-row"),
                  messages = formGroup.querySelector(".js-message");

            resetFormGroup(formGroup);

            if (errors) {
                formGroup.classList.add("has-error");

                if (formGroup.parentNode.classList.contains('form-children'))  {
                    document.getElementById(formGroup.parentNode.dataset.id).parentNode.parentNode.classList.add('has-error');
                }

                _.each(errors, function(error) {
                    if (messages) {
                        addError(messages, error);
                    }
                });
            } else {
                formGroup.classList.add("has-success");

                if (formGroup.parentNode.classList.contains('form-children'))  {
                    document.getElementById(formGroup.parentNode.dataset.id).parentNode.parentNode.classList.remove('has-error');
                }
            }
        };

        // Recusively finds the closest parent that has the specified class
        function closestParent(child, className) {
            if (!child || child == document) {
                return null;
            }
            if (child.classList.contains(className)) {
                return child;
            } else {
                return closestParent(child.parentNode, className);
            }
        };

        function resetFormGroup(formGroup) {
            // Remove the success and error classes
            formGroup.classList.remove("has-error");
            formGroup.classList.remove("has-success");
            // and remove any old messages
            _.each(formGroup.querySelectorAll(".help-block.error"), function(el) {
                el.parentNode.removeChild(el);
            });
        }

        // Adds the specified error with the following markup
        // <p class="help-block error">[message]</p>
        function addError(messages, error) {
            const block = document.createElement("p");
            block.classList.add("help-block");
            block.classList.add("error");
            block.innerText = error;
            messages.appendChild(block);
        }

        function startAjax() {
            form.querySelector('button').classList.add('loading');
        }

        function finishAjax() {
            form.querySelector('button').classList.remove('loading');
        }

        const formToJSON = (elements) =>
          [].reduce.call(
            elements,
            (data, element) => {
                if (element.type === 'radio') {
                    if (element.checked) {
                        data[element.name] = element.value;
                    }
                } else {
                    if (element.name == 'phone') {
                        data[element.name] = element.value.replaceAll(" ", "");
                    } else {
                        data[element.name] = element.value;
                    }
                }
              return data;
            },
            {},
        );

        function submitForm() {

            const postData = new FormData(form);

            /* test */

            // Toastify({
            //     text: document.querySelector('.js-coFormThanks').innerHTML,
            //     duration: 5000,
            //     className: "info",
            //     gravity: "top",
            //     position: "center",
            //     stopOnFocus: true,
            // }).showToast();

            finishAjax();

            for (const [key, value] of postData) {
                console.log(`${key}: ${value}\n`);
            }
/**/
            // let url = 'http://form.suus.test/api/form/febe03b4-c635-47fa-bb22-ffbde0ab60ff'
            let url = form.getAttribute('action');
            postData.append("email2", "");


            const xmlHttp = new XMLHttpRequest();

            xmlHttp.onload = function() {
                if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
                    Toastify({
                        text: document.querySelector('.js-coFormThanks').innerHTML,
                        duration: 5000,
                        className: "info",
                        gravity: "top",
                        position: "center",
                        stopOnFocus: true,
                    }).showToast();
                    form.reset();
                } else { }
               finishAjax();
            };

            xmlHttp.open('post', url, true);
            xmlHttp.setRequestHeader("X-Requested-With", "XMLHttpRequest");
            xmlHttp.send(postData);

        }

        function showSuccess() {
            startAjax();
            submitForm();
        }

        // Custom Validate for telephone
        validate.validators.telCheck = function(value, options, key, attributes) {
            if (value) {
                let phoneNumber = parseMin(value, 'PL');

                if (phoneNumber.isValid()) {
                    return;
                } else {
                    return 'Wprowadź poprawny numer';
                }
            }
        };
    };

    const telFormat = function() {
        let inputs = document.getElementsByTagName('input');

        for(let i = 0; i < inputs.length; i++) {
            if(inputs[i].type.toLowerCase() == 'tel') {
               inputs[i].addEventListener("keyup", event => {
                    let val_old = inputs[i].value,
                        val_new = new AsYouType('PL').input(inputs[i].value);
                    inputs[i].value = val_new;
                });
            }
        }
    }();


    // Init

    const coform = ()=> {

        const form = document.querySelector('.js-coForm');

        // Detach in mobile
        const detach = (node, target)=> {
    		let parent = node.parentNode,
    		    next = node.nextSibling;

    		if (!parent) { return; }
    		parent.removeChild(node);
    		target.append(node, next);
    	};

    	const movable = document.querySelector('.js-movable'),
    	      mediaQuery = '(max-width: 860px)',
        	  mediaQueryList = window.matchMedia(mediaQuery);

        mediaQueryList.addEventListener('change', event => {
            event.matches ? detach(movable, form) : detach(movable, document.querySelector('.js-left-col'));
        })

        mediaQueryList.matches ? detach(movable, form) : detach(movable, document.querySelector('.js-left-col'));


        // Form's constraints
        const constraints = {
            agreement_job1: {
                presence: { message: "Zaakceptuj zgodę" },
                inclusion: {
                    within: [true],
                    message: "^Zaakceptuj zgodę"
                }
            },
            agreement_job2: {
                presence: { message: "Zaakceptuj zgodę" },
                inclusion: {
                    within: [true],
                    message: "^Zaakceptuj zgodę"
                }
            },

            "cooperation[]": {
                presence: true,
            },
            "trailer_set[]": {
                presence: true,
            },
            "transport[]": {
                presence: true,
            },
            "source[]": {
                presence: true,
            },

            company: {
                presence: { message: "Pole nie może być puste" },
                format: {
                    pattern: "[a-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ0-9 ,.'-]+",
                    flags: "i",
                    message: "Może zawierać tylko znaki a-z i 0-9"
                }
            },
            email: {
                presence: { message: "Pole nie może być puste" },
                email: { message: "To nie jest poprawny adres" }
            },
            tel: {
                presence: { message: "Pole nie może być puste" },
                telCheck: "Podaj poprawny numer"
            },
            post_code: {
                presence: { message: "Pole nie może być puste" },
                format: {
                    pattern: '[0-9]{2}[-][0-9]{3}',
                    flags: "i",
                    message: "Wprowadź poprawny kod"
                }
            },
        };

        init(form, constraints);

        let monit = document.querySelectorAll('.js-monit');

        for (let i = 0; i < monit.length; i++) {
            monit[i].addEventListener('change', (e)=> {
                let children = document.querySelector("[data-id="+e.target.id+"]");

                if (e.target.checked) {
                    children.style.display = 'block';
                } else {
                    let inputs = children.querySelectorAll('input');
                    children.removeAttribute('style');

                    for (let j = 0; j < inputs.length; j++) {
                        inputs[j].checked = false;
                    }
                }
/*
                if (e.target.id === 'transport_miedzynarodowy') {
                    let children = document.querySelector("[data-id="+e.target.id+"]");

                    if (e.target.checked) {
                        children.style.display = 'block';
                    } else {
                        let inputs = children.querySelectorAll('input');
                        children.removeAttribute('style');

                        for (let j = 0; j < inputs.length; j++) {
                            inputs[j].checked = false;
                        }
                    }
                }
*/
            })
        }
    };

    document.querySelector('.js-coForm') ? coform() : false;

}, false);
